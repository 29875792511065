import React, { useState, useEffect } from "react";
import "./Carousel.css";
import textJson from "../TextJson/TextJson.json";
import { fetchRestaurantData } from '../utils/api'; 


function Carousel() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [itemsToShow, setItemsToShow] = useState(1);
    const [foods, setFoods] = useState([]);
    const nameRestaurant = textJson.refRestaurant;

    useEffect(() => {
        const fetchFoodsAndCategories = async () => {
          try {
            const { success, products, categories, error } = await fetchRestaurantData(nameRestaurant);
    
            if (!success) throw new Error(error);
    
            const filteredProducts = products.filter(product => !product.is_deleted && product.category_id === textJson.categoryId && product.image_url !== null);
            
            setFoods(filteredProducts);
            //console.log(filteredProducts, categories);
            
          } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
          }
        };
    
        fetchFoodsAndCategories();
      }, [nameRestaurant]);

    const formatText = (text) => {
        return text.split('\n').map((line, index) => (
            <span key={index}>
                {line}
                <br />
            </span>
        ));
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setItemsToShow(1);
            } else if (window.innerWidth < 1200) {
                setItemsToShow(2);
            } else {
                setItemsToShow(3);
            }
        };

        window.addEventListener("resize", handleResize);
        handleResize(); // Définir la taille initiale

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex - itemsToShow;
            return newIndex < 0 ? Math.max(0, foods.length - itemsToShow) : newIndex;
        });
    };
    

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex + itemsToShow;
            return newIndex >= foods.length ? 0 : newIndex;
        });
    };
    

    const displayItems = foods.slice(
        currentIndex,
        Math.min(currentIndex + itemsToShow, foods.length)
    );
    
    return (
        <div className="containerGlobalCarousel" id="carousel">
            <h2 className="titleCarousel">{formatText(textJson.titleCarousel)}</h2>
            <div className="carousel">
                {/*<button onClick={handlePrevClick} className="arrow left-arrow">&lt;</button>*/}
                <div className="carousel-content">
                    {displayItems.map((item, index) => (
                        <div key={index} className="carousel-item">
                            <img className="imgCarousel" src={`${item.image_url}`} alt={item.title} />
                            <p className="textImgCarousel">{item.title}</p>
                        </div>
                    ))}
                </div>
                {/*<button onClick={handleNextClick} className="arrow right-arrow">&gt;</button>*/}
            </div>
        </div>
    );
}

export default Carousel;
