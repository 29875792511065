import React from "react";
import "./Footer.css"
import textJson from "../TextJson/TextJson.json"

function Footer(){
    return(
        <div>

            <div className="lineFooter"></div>

            <div className="containerGlobalFooter">
                <p className="textFooter"><span className="textFooterLight">Réalisé par</span> YumEats</p>
                <p className="textFooter textUppercaseFooter">{textJson.restaurantName}</p>
                <p></p>
            </div>

        </div>
    )
}

export default Footer