import React from "react";
import "./Map.css";

function Map() {
    return (
        <div className="containerGlobalMap">
            <iframe width="1920" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=1920&amp;height=400&amp;hl=en&amp;q=165%20bd%20Victor%20Hugo%20Saint-Ouen-sur-Seine+(Saiko%20ramen)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> <a href='http://mapswebsite.net/fr'>MapsWebsite.net/fr</a> <script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=e2353437a6c0a6c4d1cb7cb40b5ef7d81fa6a1d0'></script>
        </div>
    );
}

export default Map;
