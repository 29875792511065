import React, { useState, useEffect } from 'react';
import './Menu.css';
import { fetchRestaurantData } from '../utils/api';
import textJson from "../TextJson/TextJson.json";

// Composant Toast
const Toast = ({ message, show, onHide }) => {
  useEffect(() => {
      if (show) {
          const timer = setTimeout(() => {
              onHide();
          }, 3000);
          return () => clearTimeout(timer);
      }
  }, [show, onHide]);

  return (
      <div className={`toast ${show ? 'show' : ''}`}>
          <span className="material-symbols-outlined">check_circle</span>
          <p>{message}</p>
      </div>
  );
};

function Menu({itemMin, itemMax, menuHome}) {
  const [filter, setFilter] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(getItemsPerPage());
  const [foods, setFoods] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cartUpdated, setCartUpdated] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const nameRestaurant = textJson.refRestaurant;
  const showImages = textJson.showMenuImages || false;

  useEffect(() => {
    const fetchFoodsAndCategories = async () => {
      try {
        const { success, products, categories, error } = await fetchRestaurantData(nameRestaurant);
        if (!success) throw new Error(error);
        const filteredProducts = products.filter(product => !product.is_deleted);
        setCategories(categories);
        setFoods(filteredProducts);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };

    fetchFoodsAndCategories();
  }, [nameRestaurant]);

  useEffect(() => {
    const handleResize = () => setItemsPerPage(getItemsPerPage());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function getItemsPerPage() {
    return window.innerWidth <= 1062 ? itemMin : itemMax;
  }

  const filterItems = (items) => {
    if (filter === 'all') return items;
    return items.filter(item => item.category_id === categories.find(cat => cat.name === filter)?.id);
  };

  const filteredItems = filterItems(foods);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, document.getElementById('menu').offsetTop);
  };

  const addToLocalStorage = (food) => {
    let storedFoods = JSON.parse(localStorage.getItem('cartItems')) || [];
    const existingFoodIndex = storedFoods.findIndex(item => item.id === food.id);

    if (existingFoodIndex !== -1) {
      storedFoods[existingFoodIndex].quantity += 1;
    } else {
      storedFoods.push({ ...food, quantity: 1 });
    }

    localStorage.setItem('cartItems', JSON.stringify(storedFoods));
    setToastMessage(`${food.name} ajouté au panier`);
    setShowToast(true);
    setCartUpdated(!cartUpdated);
  };

  return (
    <>
      <Toast 
        message={toastMessage}
        show={showToast}
        onHide={() => setShowToast(false)}
      />

      <div className="containerGlobalMenu" id='menu'>
        <div className='lineMenu'></div>
        <h2 className="titleMenu">MENU</h2>
        <div className="filter">
          <select 
            id="category-filter" 
            onChange={(e) => {
              setFilter(e.target.value);
              setCurrentPage(1);
            }}
          >
            <option value="all">Tous</option>
            {categories.map((category) => (
              <option key={category.id} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        
        <h2 className={`categoryTitle ${filter === 'all' ? 'allProducts' : ''}`}>
          {filter === 'all' ? 'Tous nos produits' : filter.charAt(0).toUpperCase() + filter.slice(1)}
        </h2>

        <div className={`menu ${showImages ? 'menu-with-images' : ''}`}>
          {currentItems.length > 0 ? (
            currentItems.map((item) => (
              <div key={item.id} className={`menu-item ${showImages ? 'with-image' : ''}`}>
                {showImages && item.image_url && (
                  <div className="item-image-container">
                    <img 
                      src={item.image_url} 
                      alt={item.name}
                      className="item-image"
                      onError={(e) => {
                        e.target.src = '/placeholder-food.jpg';
                      }}
                    />
                  </div>
                )}
                
                <div className={showImages ? 'item-content' : ''}>
                  <div className='titlePriceProduct'>
                    <div className="item-name">{item.name}</div>
                    <div className="item-price">{item.price.toFixed(2)} €</div>
                  </div>
                  <div 
                    className="item-description" 
                    data-full-description={item.description}
                  >
                    {item.description}
                  </div>
                  {menuHome && (
                    <button 
                      onClick={() => addToLocalStorage(item)} 
                      className='addLocalStorage'
                    >
                      Ajouter
                    </button>
                  )}
                </div>
              </div>
            ))
          ) : (
            <p>Aucun produit à afficher.</p>
          )}
        </div>

        <div className="pagination">
          {Array.from({ length: Math.ceil(filteredItems.length / itemsPerPage) }, (_, i) => i + 1).map(number => (
            <button
              key={number}
              onClick={() => paginate(number)}
              className={number === currentPage ? 'active' : ''}
            >
              {number}
            </button>
          ))}
        </div>
      </div>
    </>
  );
}

export default Menu;