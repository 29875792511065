import React from "react";
import textJson from "../components/TextJson/TextJson.json"

function OrderSuccess(){

    const email = localStorage.getItem('userEmail')

    return(
        <>
            <h1 style={{marginTop: "150px", textAlign:'center', color: "var(--fourth-color)"}}>Merci pour votre commande !</h1>
            <p style={{textAlign:"center", color: "var(--fourth-color)"}}>Nous avons bien reçu votre commande, un email de confirmation vous à été envoyé à <span style={{color: "var(--primary-color)"}}>{email}</span>. <br /><br />
               Vous y trouverez votre numéro de commande à transmettre lors de la récupération de votre commande. <br />
               
            </p>
            {textJson.phone && <p style={{textAlign:"center", color:"var(--fourth-color)"}}>Si vous rencontrez un problème ou avez besoin d'informations suplémentaires, merci de nous contacter au : <span style={{color: "var(--primary-color)"}}>{textJson.phone}</span></p>}

            <p style={{marginBottom: window.innerWidth < 768 ? "50px" : "100px", textAlign:"center", color: "var(--fourth-color)"}} >L'équipe <span style={{fontWeight:"bold", color: "var(--primary-color)"}}>{textJson.restaurantName}</span> vous remercie ! </p>

        </>
    )
}

export default OrderSuccess